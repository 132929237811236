import React from "react";
import BKM from "../images/logos/bkm.svg";
import NeustartKultur from "../images/logos/neustartkultur.svg";
import Dachverband from "../images/logos/dachverband.png";

const Logos = () => {
  return (
    <div className="mt-20 bg-white rounded p-6">
      <h3 className="text-3xl text-black mb-6">Gefördert durch:</h3>
      <div className="grid grod-cols-1 lg:grid-cols-3 gap-20 text-white">
        <img
          src={BKM}
          alt="Bundesregierung für Kultur und
      Medien"
        />
        <img
          className="w-60 sm:text-center sm:mx-auto"
          src={NeustartKultur}
          alt="Neustart Kultur
      Medien"
        />
        <img
          src={Dachverband}
          alt="Dachverband Tanz Deutschland
      Medien"
        />
      </div>
      <p className="mt-6 text-base text-black">
        Gefördert durch die Beauftragte der Bundesregierung für Kultur und
        Medien im Programm NEUSTART KULTUR, Hilfsprogramm DIS-TANZEN des
        Dachverband Tanz Deutschland
      </p>
    </div>
  );
};

export default Logos;
