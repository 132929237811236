import React from "react";
import Profile from "../components/Profile";
import Project from "../components/Project";
import Greta from "../images/team/greta.jpg";
import Martina from "../images/team/martina.jpg";
import Eva from "../images/team/eva.jpg";
import Emma from "../images/team/emma.jpg";
import Elvan from "../images/team/elvan.jpg";
import Aaron from "../images/team/aaron.jpg";
import Natascha from "../images/team/natascha.jpg";
import Kids from "../images/team/kids.jpg";
import David from "../images/team/david.jpg";
import Felix from "../images/team/felix.jpg";
import Florian from "../images/team/florian.jpg";
import EmilHannah from "../images/team/emilhannah.jpg";
import Logos from "../components/Logos";
import Crime from "../images/project/crime_scene_kitchen.jpg";
import DoYouSeeMe from "../images/project/do_you_see_me_.jpg";
import Friendshipped from "../images/project/friendshipped.jpg";
import SoloGreta from "../images/project/solo_greta.jpg";
import Watermelon from "../images/project/watermelon.jpg";
import Zwischenmenschlich from "../images/project/zwischen_menschlich.jpg";

const Page = ({ page, title }) => {
  return (
    <div className="cursor-default bg-black w-screen min-h-screen h-auto z-20 absolute fs-fadein-fast p-6 xl:p-0">
      <div className="container mx-auto px-30 max-w-6xl">
        <div className="my-40 text-white">
          <h1 className="text-white text-6xl text-left mb-10">{title}</h1>
          {page === "team" && (
            <>
              <div className="grid grod-cols-1 lg:grid-cols-3 gap-20 mt-20">
                <Profile
                  image={Greta}
                  name="Greta Schuster"
                  role="Choreographer of the project, dancer in 'Daily Routine'"
                  descriptionEN="Greta lives as a choreographer, dancer and dance-teacher since 2019 in Berlin. She graduated from 'The Place-London Contemporary Dance School' and was dancing in pieces of Xinxin Song, Rick Nodine, Anima May and Rixa Rottonara, among others. Her own work got shown at the APT Deptford Gallery, the Robin Howard Theatre and the Alps-move Festival."
                  descriptionDE="Greta lebt als Choreografin, Tänzerin und Tanzpädagogin seit 2019 in Berlin. Sie erhielt ihren Abschluss im zeitgenössischen Tanz am 'The Place-London Contemporary Dance School' und tanzte unter anderem in Stücken von Rick Nodine, Xinxin Song, Anima May und Rixa Rottonara. Ihre eigene Arbeit wurde in der APT Deptford Gallery, dem Robin Howard Theatre und dem Alps-move Festival gezeigt."
                />
                <Profile
                  image={Martina}
                  name="Martina Cavinato"
                  role="Videographer of the project"
                  descriptionEN="Martina is a video maker and Photographer from Padova (IT). She studied Visual Arts first at IUAV university in Venice and then in Kunsthochschule Kassel (DE). She lived in Berlin for a while where she could explore and deepen her knowledge in dance films. She makes mostly dance films, music videos and short documentaries. "
                  descriptionDE="Martina ist ein Filmemacher und Fotografin aus Padova (IT): Sie studierte Visual Arts an der IUAV Universität in Venedig und an der Kunsthochschule in Kassel (DE). Sie lebte eine Zeit lang in Berlin, wo sie ihre Leidenschaft für Tanzfilme ausleben und sich ausprobieren konnte. Sie macht hauptsächlich Tanzfilme, Musikvideos und kurze Dokumentationen."
                />
                <Profile
                  image={Eva}
                  name="Eva Leemans"
                  role="Dancer in 'Friend-shipped', 'Crime Scene Kitchen', choreographer and dancer in 'Do you see me?'"
                  descriptionEN="After graduating from Northern School of Contemporary Dance she's been working as a performer and co-creator with Excessive Human Collective in Leeds (UK). Her first solowork Reality Check has been presented in 2019 at Pertanzform in Berlin and in Waschhaus Arena Potsdam.  Eva is interested in the body as an expressive medium, bridging internal and external realities, and movement research as an investigation of possibilities, relations and connectivity."
                  descriptionDE="Nach ihrem Studium and der 'Northern School of Contemporary Dance'arbeitete Eva als Performerin und Mitschöpferin des 'Excessive Human Collective' in Leeds (UK): Ihre erste Solo-Arbeit Reality Check wurde 2019 beim Pertanzforum in Berlin und in der Waschhaus Arena Potsdam gezeigt."
                />
                <Profile
                  image={Emma}
                  name="Emma Hedemann Christensen"
                  role="Dancer in 'Friend-shipped'"
                  descriptionEN="Emma is a contemporary dance artist from Copenhagen, Denmark. Emma has worked as a freelance dancer with various choreographers and collectives including José Vidal, Julian Yopasa, Intercollective Berlin, INNER tanz und performance and Saori Hala. With these artists she has performed at venues such as Kampnagel Kultur Fabrik, Uferstudios, Centro de Residencia NAVE, Perspektiven Attersee Festival and Santiago a Mill Festival."
                  descriptionDE="Emma ist eine zeitgenössische Tänzerin as Kopenhagen, Dänemark. Sie arbeitete als freiberufliche Tänzerin mit verschiedenen Choreografen und Kollektiven, unteranderem mit Jose Vidal, Julian Yopasa, Intercollective Berlin, INNER tanz und performance und Saori Hala. Mit diesen Künstler*innen per-formte sie in Kampnagel Kultur Fabrik, den Uferstudios, Centro Residencia NAVE, Perspektiven Attersee Festival und dem Santiago a Mill Festival."
                />
                <Profile
                  image={Elvan}
                  name="Elvan Tekin"
                  role="Dancer in 'Crime Scene Kitchen'"
                  descriptionEN="Elvan Tekin(she/her) is a dance artist and translator from Turkey based in Hamburg/Berlin. As a dancer she worked among others with Eyal Dadon (ORPHEUS, Thalia Theater Hamburg), José Vidal (EMERGENZ, Kampnagel Hamburg; EMERGER, NAVE Santiago de Chile) and Edan Gorlicki (LOST BODIES-URBAN ENCOUNTERS, Inter-Actions Heidelberg). In her choreography, she focuses on socio-political dimensions of the individual body as a form of resistance, implementing and experimenting with translation theories as a tool for creation."
                  descriptionDE="Elvan Tekin(sie/ihr) stammt aus der Türkei, lebt in Berlin/Hamburg und ist als Übersetzerin und Tänzerin tätig. Auf der Bühne arbeitete sie unter anderem mit Eyal Dadon (ORPHEUS, Thalia Theater Hamburg) und José Vidal (EMERGENZ, Kampnagel Hamburg; EMERGER, NAVE Santiago de Chile) und Edan Gorlicki(LOST BODIES-URBAN ENCOUNTERS, Inter-Actions Heidelberg). Der Schwerpunkt ihrer künstlerischen Arbeit liegt auf den soziopolitischen Dimensionen von Körpern als Form des Widerstands."
                />
                <Profile
                  image={Aaron}
                  name="Aaron Carrey-Burrows"
                  role="Dancer in 'Crime Scene Kitchen'"
                  descriptionEN="Aaron is a dancer and artist from Australia. He graduated from the Western Australian Academy of Performing Arts in 2017 after completing his Honours with LINK Dance Company. Since 2019 Aaron has been living in Berlin and has worked with the Deutscher Oper Ballet, the Deutsches Theater, Jazz Dance Theater, Trauma Bar und Kino among others. Currently Aaron dances with the Tanz Kollektiv Luna Park and is deeply involved in the creation process of a work called 'Birds of Paradise'."
                  descriptionDE="Aaron ist ein Tänzer und Künstler aus Australien. Er erhielt seine Abschluss and der Western Australian Academy of Performing Arts im Jahre 2017 und arbeitete mit der LINK Dance Company. Seid 2019 lebt Aaron in Berlin und tanzte unter anderem, an der Deutschen Oper, dem Deutschem Theater, dem Jazz Dance Theater und  in der Trauma Bar und Kino. Zurzeit tanzt Aaron mit dem Tanz Kollektiv Luna Park und ist mitten im Prozess"
                />
                <Profile
                  image={Natascha}
                  name="Natascha Roy"
                  role="Dancer in 'Zwischen Menschlich'"
                  descriptionEN="Natascha studied at the legendary SNDO Amsterdam in the nineties and worked since then as a performer,teacher and creator in a wide range of formats. Besides her artistic interest she collaborates with experts of the political education field to create empowerment and antiracism projects, which include the language of the body."
                  descriptionDE="Natascha studierte an der SNDO Amsterdam in den neunziger Jahren. Seitdem arbeitete sie als Performerin, Tanzpädagogin und Tanzschaffende in breiten Spektren und Formaten. Neben ihren künstlerischen Tätigkeiten arbeitet sie auch mit Experten der politischen Entwicklung zusammen an Empowerment- und Antirassismus-Projekten, die die Körpersprache miteinbeziehen."
                />
                <Profile
                  image={David}
                  name="David Mariano"
                  role="Dancer in 'Zwischen Menschlich'"
                  descriptionEN="David is a Mexican dancer, teacher, choreographer and cultural manager with more than 17 years of experience.  His work focuses on the connection and communication of the body in movement. Using improvisation as a tool for creation, his work explores the scenic presence, the appropriation of space through dance, and activities that approach the values of being human."
                  descriptionDE="David ist ein mexikanischer Tänzer, Tanzpädagoge, Choreograph und Kulturmanager, seid nun mehr als 17 Jahren. Sein Hauptinteresse liegt in der Verbindung der Kommunikation des Körpers in Bewegung. Er nutzt die  Improvisation als ein Mittel um Stücke zu kreieren. Seine Werke erforschen die szenische Präsenz, die Aneignung des Raums durch den Tanz und die Frage, was es denn bedeutet Mensch zu sein."
                />
                <Profile
                  image={Kids}
                  name="Alma, Ida, Inka, Juli"
                  role="Dancer in 'Watermelon'"
                  descriptionEN="Alma, Ida, Inka and Juli are going to school and are dancing and training in the dance-club 'flatback-and-cry'."
                  descriptionDE="Alma, Ida, Inka und Juli gehen zur Schule und tanzen im Tanzverein 'flatback-and-cry'."
                />
                <Profile
                  image={Felix}
                  name="Felix Vogelgesang"
                  role="music for 'friend-shipped', audio-help"
                  descriptionEN="Felix is songwriter and guitarist for his band VIANOVA. He likes to experiment with many different music genres, from metalcore, to acoustic songs, to electronic tracks. For this project he made the soundtrack for 'friend-shipped' and was a huge help to mix the sounds for the other Videos."
                  descriptionDE="Felix ist Songwriter und Gitarrist seiner Band VIANOVA. Er probiert sich mit verschiedenen Genres aus, von Metalcore, über akustische Songs, zu elektronischen Tracks. Für dieses Projekt machte er den Soundtrack für 'friend-shipped' und arbeitete an den Sounds der anderen Videos."
                />
                <Profile
                  image={Florian}
                  name="Florian Schiesterl"
                  role="Webdesign, webdevelopment"
                  descriptionEN="Flo is a UI/UX designer based in Vienna. He believes in the power of simplicity. For this project he developed the website."
                  descriptionDE="Flo ist ein UI/UX Designer aus Wien. Er glaubt an die Kraft der Einfachheit. Für dieses Projekt hat er die Website programmiert."
                />
                <Profile
                  image={EmilHannah}
                  name="Emil und Hannah"
                  role="Illustration"
                  descriptionEN="Emil and Hannah are an architect and a primary school teacher from Vienna. Together they created the illustrations for this website."
                  descriptionDE="Emil und Hannah sind ein Architekt und eine Grundschullehrerin aus Wien. Sie haben sich um die Illustration dieser Website gekümmert."
                />
              </div>
              <p className="text-white mt-20 mb-20 text-base">
                Ein grosses Dankeschön geht an LAURA, PAUL, ANNA LENA (die ganze
                Euler-WG), INGA, dafür, dass sie uns ihre Wohnungen zur
                Verfügung gestellt haben.
              </p>
              <Logos />
            </>
          )}
          {page === "project" && (
            <>
              <h3 className="text-3xl mt-20">To be a fly on the wall</h3>
              <div className="grid grod-cols-1 lg:grid-cols-2 gap-20 mb-40">
                <p className="mt-6 text-base">
                  “To be a fly on the wall” is an interactive online
                  dance-performance. The idea is to create a possibility for the
                  audience to build their own performance-experience and to
                  actively decide where to go in the virtual space instead of
                  sitting in front of the screen and watching passively. The
                  freedom to change the costumes, the music or the perspective
                  while watching the dance is given through the website. Here is
                  a brief description about each film:
                </p>
                <p className="mt-6 text-base opacity-50">
                  “To be a fly on the wall” ist eine interaktive Tanzperformance
                  im virtuelle Raum. Die sechs verschiedenen Kurzfilme gewähren
                  Einblick in das Geschehen, das sich hinter verschlossener Tür
                  abspielt. Das Publikum kann sich frei durch die Webseite
                  bewegen und die Kostüme, den Sound oder die Perspektive der
                  Szenen ändern. Hier ist eine kurze Beschreibung der Filme:
                </p>
              </div>

              <div className="grid grod-cols-1 lg:grid-cols-2 gap-20">
                {/*<Project
                  image={}
                  name=""
                  dancers=""
                  music=""
                  descriptionEN=""
                  descriptionDE=""
                />*/}
                <Project
                  image={Friendshipped}
                  name="Friend-shipped"
                  dancers="Dancers: Emma Hedemann Christensen, Eva Leemans"
                  music="Music: Felix Vogelgesang"
                  descriptionEN="Tells the story about old friendship between two people who seem to only find their ways when thinking about the past, but are not really able to connect to each other in the moment. While one person wants to move on and build a future, the other one seems to be stuck with the image of what their friendship used to be. The atmosphere changes from the all green tones to the vintage-costumes. With the changed costumes of 'Alice in Wonderland' the story changes completely, the friends disappear and become Alice (Eva) and the Queen of hearts ( Emma). When the costumes change into an 'Alice in Wonderland' theme, the story changes completely, the friends disappear and become 'Alice' and 'The Queen of Hearts'."
                  descriptionDE="Eine langjährige Freundschaft, die anscheinend nur mehr in der Vergangenheit gut zusammen passt und noch in den Erinnerungen gemeinsame Momente und Freuden findet, hat Schwierigkeiten in der Gegenwart daran anzuknüpfen. Während eine Person eine gemeinsame Zukunft planen will, schwirrt die andere noch in den Erinnerungen herum. Durch den Kostümwechsel der Grüntöne, in das Vintage-outfit verändert sich die Stimmung des Tanzes. Mit dem Wechsel zum  'Alice im Wunderland' Thema, ändert sich die Geschichte der Choreografie komplett, die zwei Freunde verschwinden und verwandeln sich zu Alice (Eva) und der Queen of Hearts (Emma)."
                />
                <Project
                  image={Crime}
                  name="Crime Scene Kitchen"
                  dancers="Dancers: Elvan Tekin, Aaron Carey-Burrows, Eva Leemans"
                  music="Music: Arnalds, Ólafur, “So Close.”, by Ólafur Arnalds, Broadchurch soundtrack. Mercury KX,2015. London Grammar, “Strong.”, by The Orchard Enterprises, Workout Buddy 2014. Blue Nile Music, 2014."
                  descriptionEN="After years of domestic abuse, a fight gets out of hand and the couple's kitchen turns into a crime scene. The woman believes to have killed her boyfriend and calls the neighbor for help. That's when the situation really escalates. In the first video we see the scene filmed from the outsider's perspective. The other two videos are filmed with a GoPro both on her and on his head in order to show both views."
                  descriptionDE="Nach jahrelangem Missbrauch, gerät ein Streit des Paares außer Kontrolle und die eigene Küche wird zum Tatort. Die Frau glaubt ihren Freund ermordet zu haben und ruft ihre Nachbarin zur Hilfe. Ab diesem Moment wird alles nur noch schlimmer. Im ersten Video sehen wir die Szene von einer außenstehenden Perspektiven. Die anderen zwei Videos wurden mit einer GoPro auf ihrem und seinem Kopf gefilmt, um in beiden Sichtweisen Einblick zu gewähren. "
                />
                <Project
                  image={SoloGreta}
                  name="Daily Routine"
                  dancers="Dancer: Greta Schuster"
                  youtube="Referred youtube-links: https://www.youtube.com/watch?v=PYFSs06DcEk&t=214s, https://www.youtube.com/watch?v=F83u21GbI4k&t=69s, https://www.youtube.com/watch?v=t0wRme2HT9I&t=390s"
                  music="Music: Léo Chauliac, “Que Reste-t-il de nos amours?”, by Avalon Jazz Band, I wish you love. Avalon Jazz Band, 2016. AeoX, “culture houze.”, by AeoX, Bonking Berlin Bastards.  A-TON, 2021. Händel, Georg Friedrich, “Violin sonatas in E-Major, Op.1 Nr. 12B, HWV 373:IV Allegro.”, by Aaron Rosand & Hugh Sung, Handel: Violin Sonatas. Biddulph Recordings,2020"
                  descriptionEN="This solo describes a life that is entirely driven and controlled by what happens on the screen. The internet decides on the weather and dictates the activity the character is meant to do according to it. On the website we see three different weather situations and matching day-plans: sunny, rainy and cloudy. Why leaving the comfortable environment of your own flat, if you can have 'the best things in the world' directly in your living-room?"
                  descriptionDE="Das Solo beschreibt ein Leben das digital geleitet wird und der Bildschirm dabei das aktuelle Wetter und die dazugehörige Tagesaktivität bestimmt. In den Videos selbst werden drei verschiedene Wettersituationen gezeigt: sonnig, regnerisch und wolkig. Warum sollte man eigentlich die komfortable Umgebung seiner Wohnung verlassen, wenn das Internet 'die besten Dinge der Welt' direkt ins Wohnzimmer bringen kann?"
                />
                <Project
                  image={Watermelon}
                  name="Watermelon"
                  dancers="Dancers: Alma, Ida, Inka, Juli"
                  music="Music: Novo Amor, “Decimal.” By Novo Amor. Cannot Be, Whatsoever. All Points, 2020.  Jet, “Are You Gonna Be My Girl.” By Jet. Get Born. Electra Entertainment Group, 2003. Billie Eilish, Finneas O`Connell, “Everything I wanted.” By Billie Eilish. Darkroom/Interscope Records, 2019. David, Damiano “Zitti e buoni.” By Måneskin. Teatro D`Ira-Vol.1. Sony Music Entertainment Italy S.p.a., 2021. Mozart, Amadeus “Piano Sonata Nr.11 in A, K.331, andante grazioso.” By Maria João Pirez. Mozart:Piano Sonatas. Deutsche Grammophon GmbH, 2016. Van Beethoven, Ludwig “Symphonie Nr. 5 in C-Minor, Op. 67.”, by Vienna Philharmonic & Carlos Kleiber. Carlos Kleiber: Complete Recordings on Deutsche Grammophon. Deutsche Grammophon GmbH, 2010."
                  descriptionEN="Often kids are described as carefree and unworried. What is often not perceived, is, that many of them are under a lot of pressure to perform, worry about climate change and their future. Probably more often they should be allowed to be 'cheeky and wild and wonderful', as Pippi Longstocking has thought about the world. There are three different music-tracks to the same Video.  "
                  descriptionDE="Oft werden Kinder als unbeschwert und sorglos wahrgenommen. Was man oft nicht sieht, ist, dass viele unter enormen Leistungsdruck stehen und sich Sorgen um den Klimawandel und ihre Zukunft machen. Vielleicht zu selten wird es Kindern erlaubt 'frech und wild und wunderbar' zu sein, so wie es Pippi Langstrumpf vorgemacht hat. Es ist möglich, die Musik zur selben Choreografie zu ändern."
                />
                <Project
                  image={DoYouSeeMe}
                  name="Do you see me?"
                  dancers="Choreographed and danced by: Eva Leemans"
                  music="Music: Talbot, Richard; Crossley, Jamie; Meadows, Duncan “Weightless, Pt.1.”, Marconi union, Ambient Transmission vol.2., Just music, 2014."
                  descriptionEN="'Do you see me' is presented here as a video version of a longer work in progress. Playing with visible and invisible dynamics between set and performer, the work questions what we see when we look at another human being, in relation (or not) to the system that embeds them. What do we choose to see and what do we choose to blank out from our awareness? What are we able to see? How does our perception change the reality of the human being we’re looking at? And could it be useful to make an effort to see a human being in their totality and as part of a system of internal and external dynamics?"
                  descriptionDE="'Do you see me' zeigt einen Ausschnitt einer längeren Solo-Performance. Es geht um sichtbare und unsichtbare Dynamiken zwischen dem Set und der Performerin. Das Stück thematisiert die Frage, was wir überhaupt von einem anderen Menschen sehen, der eigentlich nur im Kontext zu einem bestehenden System wahrnehmbar ist. Entscheiden wir uns dafür, gewisse Dinge aus unserem Bewusstsein auszublenden, und andere wiederum genauer unter die Lupe zu nehmen? Ist dies überhaupt eine aktive Entscheidung? Wie ändert unsere Wahrnehmung die eigentliche Realität der betrachteten Person? Und könnte es nützlich sein, uns zu bemühen den Menschen in seinem Ganzen zu betrachten, als Teil eines Systems innerer und äußerer Dynamiken?"
                />
                <Project
                  image={Zwischenmenschlich}
                  name="Zwischen Menschlich"
                  dancers="Dancer: Natascha Roy, David Mariano Chaho"
                  music="Music: Amar, Armand “La Ballade.”, by Armand Amar. Pixel (Original Soundtrack for Pixel a Dance Performance). Long Distance, 2014.  Vernon, Justin “666 ʇ.”, by Bon Iver. 22, A Million. Jagjaguwar, 2016."
                  descriptionEN="Two different people seem to live in a similar situation. Both are trapped in their daily routine resulting in numbness of feeling. The pressure to have to function, overwhelms and stresses one person, while it makes the other one more and more passive. Until they meet, almost by accident, both seem very touched by what happens next. The three videos show different angles of teh same performance."
                  descriptionDE="Zwei unterschiedliche Menschen scheinen in einer ähnlichen Situation gefangen  zu sein, die sich aus einer Alltagsroutine und einer daraus resultierenden Gefühlstaubheit zusammensetzt. Der Druck funktionieren zu müssen, überfordert und stresst die eine Person, während die andere dadurch immer passiver wird. Als sie dann fast zufällig aufeinandertreffen, passiert etwas, das beide nachhaltig berührt. Die drei Videos zeigen verschiedene Perspektiven derselben Performance."
                />
              </div>
              <Logos />
            </>
          )}
          {page === "donate" && (
            <div>
              <h3 className="text-3xl mt-20">
                We hope you enjoyed watching and playing with our videos.
              </h3>

              <div className="grid grod-cols-1 lg:grid-cols-2 gap-20">
                <div>
                  <p className="mt-6 text-base">
                    This page is for donations. First of all we would be very
                    happy if we could receive some feedback, or hear about your
                    thoughts, your observations or ideas. You can send them to
                    our{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary"
                      href="https://instagram.com/to.be.a.fly"
                    >
                      Instagram page
                    </a>
                    , or to this E-mail adress:{" "}
                    <a
                      className="text-primary"
                      href="mailto:gretaschuster@hotmail.com"
                    >
                      gretaschuster@hotmail.com
                    </a>
                  </p>
                  <p className="mt-6 text-base">
                    The next form of donation is for money, that we are willing
                    to accept. If you have some money to spare, we would be very
                    grateful for your donation of ANY amount.
                  </p>
                  <form
                    className="mt-6"
                    action="https://www.paypal.com/donate"
                    method="post"
                    target="_top"
                  >
                    <input
                      type="hidden"
                      name="hosted_button_id"
                      value="PHJR4BT6QUL2W"
                    />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_DE/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </div>
                <div className="">
                  <p className="mt-6 text-base opacity-50">
                    Auf dieser Seite geht es um Spenden: Erstmal würden wir uns
                    sehr über dein Feedback freuen, in dem du deine Ideen,
                    Kritiken und Gedanken mit uns teilen kannst. Schreib gerne
                    auf{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary"
                      href="https://instagram.com/to.be.a.fly"
                    >
                      Instagram
                    </a>{" "}
                    oder schicke eine E-Mail an diese Adresse:
                    <a
                      className="text-primary"
                      href="mailto:gretaschuster@hotmail.com"
                    >
                      gretaschuster@hotmail.com
                    </a>
                  </p>
                  <p className="mt-6 text-base opacity-50">
                    Die andere Art des Spendens ist in Form von Geld, das wir
                    gerne annehmen, möge der Betrag noch so klein sein. Falls
                    ihr also was übrig habt, drückt gerne auf den
                    DONATION-Button.
                  </p>
                  <form
                    className="mt-6"
                    action="https://www.paypal.com/donate"
                    method="post"
                    target="_top"
                  >
                    <input
                      type="hidden"
                      name="hosted_button_id"
                      value="PHJR4BT6QUL2W"
                    />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/en_DE/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
