import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import classnames from "classnames";
import VideoWrapper from "../components/VideoWrapper";
import Button from "../components/Button";
import ButtonRound from "../components/ButtonRound";
import Door1 from "../sounds/door-1.wav";
import Door2 from "../sounds/door-2.mp3";
import Door3 from "../sounds/door-3.wav";
import Door4 from "../sounds/door-4.wav";
import Door5 from "../sounds/door-5.wav";
import Door6 from "../sounds/door-6.wav";

const MultiVideo = (place) => {
  const [showCover, setShowCover] = useState(true);
  const [showIntro, setShowIntro] = useState(true);
  const [playIntro, setPlayIntro] = useState(false);
  const [playbackTime, setPlaybackTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(0);
  const [video1Muted, setVideo1Muted] = useState(false);
  const [video2Muted, setVideo2Muted] = useState(false);
  const [video3Muted, setVideo3Muted] = useState(false);
  const [video1Volume, setVideo1Volume] = useState(0);
  const [video2Volume, setVideo2Volume] = useState(0);
  const [video3Volume, setVideo3Volume] = useState(0);
  const videoRef1 = useRef();
  const videoRef2 = useRef();
  const videoRef3 = useRef();
  const soundDoor1 = new Audio(Door1);
  const soundDoor2 = new Audio(Door2);
  const soundDoor3 = new Audio(Door3);
  const soundDoor4 = new Audio(Door4);
  const soundDoor5 = new Audio(Door5);
  const soundDoor6 = new Audio(Door6);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    //setIsPlaying(false);
    //setShowIntro(true);
    //const timeout = setTimeout(() => {
    //  stop();
    //  setIsLoading(false);
    //}, 10000);
    //return () => clearTimeout(timeout);
  }, []);

  const handleEnter = (props) => {
    if (place.videos.id === 1) {
      soundDoor1.play();
    }
    if (place.videos.id === 2) {
      soundDoor2.play();
    }
    if (place.videos.id === 3) {
      soundDoor3.play();
    }
    if (place.videos.id === 4) {
      soundDoor4.play();
    }
    if (place.videos.id === 5) {
      soundDoor5.play();
    }
    if (place.videos.id === 6) {
      soundDoor6.play();
    }

    setPlayIntro(true);

    const timeout = setTimeout(() => {
      setShowCover(false);
    }, 1500);

    return () => clearTimeout(timeout);
  };

  const handleIntroEnded = (props) => {
    setShowIntro(false);
    setVideo1();
  };

  const handleVideo1PlaybackTime = ({ playedSeconds }) => {
    if (showVideo === 0) setPlaybackTime(playedSeconds);
  };
  const handleVideo2PlaybackTime = ({ playedSeconds }) => {
    if (showVideo === 1) setPlaybackTime(playedSeconds);
  };
  const handleVideo3PlaybackTime = ({ playedSeconds }) => {
    if (showVideo === 2) setPlaybackTime(playedSeconds);
  };

  const handleVideo1Loader = (props) => {
    //console.log("video 1 loaded");
  };
  const handleVideo2Loader = (props) => {
    //console.log("video 2 loaded");
  };
  const handleVideo3Loader = (props) => {
    //console.log("video 3 loaded");
  };

  const play = () => {
    setIsPlaying(true);
  };

  function pause() {
    setIsPlaying(false);
  }

  function setVideo1() {
    //videoRef1.current.seekTo(playbackTime);
    setIsPlaying(true);
    setShowVideo(0);
    setVideo1Muted(false);
    setVideo2Muted(true);
    setVideo3Muted(true);
    setVideo1Volume(1);
    setVideo2Volume(0);
    setVideo3Volume(0);
  }
  function setVideo2() {
    videoRef2.current.seekTo(playbackTime);
    setIsPlaying(true);
    setShowVideo(1);
    setVideo1Muted(true);
    setVideo2Muted(false);
    setVideo3Muted(true);
    setVideo1Volume(0);
    setVideo2Volume(1);
    setVideo3Volume(0);
  }
  function setVideo3() {
    videoRef3.current.seekTo(playbackTime);
    setIsPlaying(true);
    setShowVideo(2);
    setVideo1Muted(true);
    setVideo2Muted(true);
    setVideo3Muted(false);
    setVideo1Volume(0);
    setVideo2Volume(0);
    setVideo3Volume(1);
  }

  function sync() {
    videoRef1.current.seekTo(playbackTime);
    videoRef2.current.seekTo(playbackTime);
    videoRef3.current.seekTo(playbackTime);
  }

  function seek() {
    videoRef1.current.seekTo(playbackTime + 10);
    videoRef2.current.seekTo(playbackTime + 10);
    videoRef3.current.seekTo(playbackTime + 10);
    setIsPlaying(true);
  }

  return (
    <div className={classnames(["fs-fadein-fast", "place-" + place.videos.id])}>
      {showIntro && (
        <div>
          {showCover && (
            <div className="fixed w-screen h-screen bg bg-door z-30 cursor-default">
              <div className="text-center w-screen h-screen flex justify-center items-center">
                <button
                  className="absolute bg-welcome w-48 h-16 bg-white rounded-full transition-all transform duration-300 ease-in-out hover:-translate-y-1 text-2xl"
                  onClick={handleEnter}
                ></button>
              </div>
            </div>
          )}

          <VideoWrapper hideVideo={false}>
            <ReactPlayer
              playing={playIntro}
              className="react-player"
              url={place.videos.intro}
              width="100%"
              height="100%"
              playsinline
              onEnded={handleIntroEnded}
            />
          </VideoWrapper>
          {/*<nav className="fixed bottom-0 z-20 w-screen fs-fadein-slow">
            <div className="m-3">
              <div className="flex justify-center align-middle w-screen">
                <Button label="Skip Intro" onClick={handleIntroEnded} />
              </div>
            </div>
          </nav>*/}
        </div>
      )}

      <VideoWrapper hideVideo={showIntro || showVideo !== 0}>
        <ReactPlayer
          ref={videoRef1}
          playing={isPlaying}
          className="react-player"
          url={place.videos.video1}
          width="100%"
          height="100%"
          onProgress={handleVideo1PlaybackTime}
          onReady={handleVideo1Loader}
          muted={video1Muted}
          volume={video1Volume}
          playsinline
          autoPlay
        />
      </VideoWrapper>

      <VideoWrapper hideVideo={showIntro || showVideo !== 1}>
        <ReactPlayer
          ref={videoRef2}
          playing={isPlaying}
          className="react-player"
          url={place.videos.video2}
          width="100%"
          height="100%"
          onProgress={handleVideo2PlaybackTime}
          onReady={handleVideo2Loader}
          muted={video2Muted}
          volume={video2Volume}
          playsinline
        />
      </VideoWrapper>

      <VideoWrapper hideVideo={showIntro || showVideo !== 2}>
        <ReactPlayer
          ref={videoRef3}
          playing={isPlaying}
          className="react-player"
          url={place.videos.video3}
          width="100%"
          height="100%"
          onProgress={handleVideo3PlaybackTime}
          onReady={handleVideo3Loader}
          muted={video3Muted}
          volume={video3Volume}
          playsinline
        />
      </VideoWrapper>

      <nav
        className={classnames([
          "fixed bottom-0 z-20 cursor-default",
          showIntro && "hidden",
        ])}
      >
        <div className="m-3">
          <div className="flex justify-left align-middle w-screen fs-fadein-slow cursor-default">
            <div className="bg-white rounded-full flex justify-left">
              <span className="m-1 inline-block bg-place-icon iconbadge w-10 h-10 rounded-full"></span>
              <ButtonRound
                active={showVideo === 0}
                label="1"
                onClick={setVideo1}
              />
              <ButtonRound
                active={showVideo === 1}
                label="2"
                onClick={setVideo2}
              />
              <ButtonRound
                active={showVideo === 2}
                label="3"
                onClick={setVideo3}
              />
            </div>
            <div className="hidden">
              {isPlaying ? (
                <Button label="Pause" onClick={pause} />
              ) : (
                <Button label="Play" onClick={play} />
              )}

              <Button label="Fast forward" onClick={seek} />

              <Button label="Sync" onClick={sync} />

              <p className="m-4 text-center">{playbackTime}s</p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MultiVideo;
