import React from "react";
import classnames from "classnames";

const Badge = ({ place, onClick, onMouseEnter }) => {
  return (
    <button
      alt={place}
      className={classnames([
        "cursor-fly badge absolute w-16 h-16 bg-white rounded-full transition-all transform duration-300 ease-in-out hover:-translate-y-1 hover:rotate-12",
        "place-" + place,
      ])}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    ></button>
  );
};

export default Badge;
