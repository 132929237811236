import React, { useState } from "react";
import classnames from "classnames";
import Page from "../components/Page";
import ArrowBack from "../components/ArrowBack";
import MenuItem from "../components/MenuItem";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showPage, setShowPage] = useState(0);

  const onToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  function goHome() {
    setShowPage(0);
    scrolltop();
    setShowMenu(false);
  }
  function goTeam() {
    setShowPage(1);
    scrolltop();
    setShowMenu(false);
  }
  function goProject() {
    setShowPage(2);
    scrolltop();
    setShowMenu(false);
  }
  function goDonate() {
    setShowPage(3);
    scrolltop();
    setShowMenu(false);
  }

  function scrolltop() {
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <ul className="fs-menu fixed top-0 right-2 z-50">
        <li
          className={classnames(["fs-hamburger show", showMenu && "open"])}
          onClick={onToggleMenu}
        >
          <div className="line1"></div>
          <div className="line2"></div>
        </li>
      </ul>

      <div
        onClick={onToggleMenu}
        className={classnames([
          "w-screen h-screen z-30 bg-black fixed transition delay-150 duration-300 ease-in-out",
          !showMenu && "opacity-0 pointer-events-none",
          showMenu && "opacity-70",
        ])}
      ></div>
      <div
        className={classnames([
          "w-full sm:w-2/3 md:w-1/3 xl:w-1/4 h-screen z-40 bg-black fixed transition delay-150 duration-300 ease-in-out right-0",
          !showMenu && "opacity-0 pointer-events-none",
          showMenu && "opacity-1",
        ])}
      >
        <ul className="my-20 mx-10">
          <MenuItem label="Home" onClick={goHome} />
          <MenuItem label="About the project" onClick={goProject} />
          <MenuItem label="About us" onClick={goTeam} />
          <MenuItem label="Support us" onClick={goDonate} />
        </ul>
      </div>
      {showPage >= 1 && (
        <div className="fixed top-0 left-0 z-50 fs-fadein-fast">
          <ArrowBack onClick={goHome} />
        </div>
      )}
      {showPage >= 1 && (
        <div className="fixed top-0 w-full h-20 bg-gradient z-40 pointer-events-none opacity-90 xl:hidden"></div>
      )}
      {showPage === 1 && <Page page="team" title="About us" />}
      {showPage === 2 && <Page page="project" title="About the project" />}
      {showPage === 3 && <Page page="donate" title="Support us" />}
    </div>
  );
};

export default Header;
