import React from "react";

const MenuItem = ({ label, onClick }) => {
  return (
    <li
      className="my-6 text-white text-4xl hover:text-primary transition duration-300 ease-in-out"
      onClick={onClick}
    >
      {label}
    </li>
  );
};

export default MenuItem;
