import React from "react";

const Project = ({
  image,
  name,
  dancers,
  youtube,
  music,
  descriptionDE,
  descriptionEN,
}) => {
  return (
    <div className="">
      {image ? (
        <img src={image} alt={name} className="mb-4 rounded" />
      ) : (
        <div className="mb-4 rounded-full w-40 h-40 bg-grey"></div>
      )}
      <h3 className="text-3xl mb-4">{name}</h3>
      <span className="text-base block mb-2 opacity-50">{dancers}</span>
      <span className="block mb-2 text-base opacity-50">{music}</span>
      {youtube && <span className="text-base">{youtube}</span>}
      <p className="mt-6 text-base">{descriptionEN}</p>
      <p className="mt-2 text-base opacity-50">{descriptionDE}</p>
    </div>
  );
};

export default Project;
