import React from "react";
import classnames from "classnames";

const VideoWrapper = (props) => {
  return (
    <div
      className={classnames([
        "fixed w-screen h-screen bg-black cursor-default",
        !props.hideVideo && "z-10",
      ])}
    >
      <div className="player-wrapper">{props.children}</div>
    </div>
  );
};

export default VideoWrapper;
