import React, { useState } from "react";
import Button from "../components/Button";
import ArrowBack from "../components/ArrowBack";
import Badge from "../components/Badge";
import MultiVideo from "../components/MultiVideo";
import ReactPlayer from "react-player";
import Header from "../components/Header";
import Fly from "../sounds/fly.wav";

const videos = {
  place1: {
    //kids
    id: 1,
    intro: "https://vimeo.com/607644293/316db1cc1b",
    video1: "https://vimeo.com/602615774/92c64b88c4",
    video2: "https://vimeo.com/602523132/031bcbff93",
    video3: "https://vimeo.com/604038280/fbfc0f4d75",
  },
  place2: {
    //eva
    id: 2,
    intro: "https://vimeo.com/607637814/951a4b930a",
    video1: "https://vimeo.com/606764966/bb4bfdc1cb",
    video2: "https://vimeo.com/607435883/d8bf003b66",
    video3: "https://vimeo.com/606893033/9e9771f3ed",
  },
  place3: {
    //murderer
    id: 3,
    intro: "https://vimeo.com/609687614/d311e2bf3b",
    video1: "https://vimeo.com/616109482/d508652bfc",
    video2: "https://vimeo.com/620728955/d4fde23498",
    video3: "https://vimeo.com/620785251/407a204867",
  },
  place4: {
    //friendshipped
    id: 4,
    intro: "https://vimeo.com/607547120/009a806139",
    video1: "https://vimeo.com/609891192/cbe563acdf",
    video2: "https://vimeo.com/609919819/bb5457f071",
    video3: "https://vimeo.com/609952626/7331373eac",
  },
  place5: {
    //zwischen menschlich
    id: 5,
    intro: "https://vimeo.com/607557081/5d338e6c7d",
    video1: "https://vimeo.com/625296987/1b47e9f321",
    video2: "https://vimeo.com/625498958/3f8962b248",
    video3: "https://vimeo.com/624850734/35d7bcf803",
  },
  place6: {
    //solo greta
    id: 6,
    intro: "https://vimeo.com/599396039/c95517b349",
    video1: "https://vimeo.com/605499426/732fe008dc",
    video2: "https://vimeo.com/605022150/ceda1b0b49",
    video3: "https://vimeo.com/604868325/22c483ea0d",
  },
};

const sound = new Audio(Fly);

const Home = () => {
  const [showPlace, setShowPlace] = useState(0);
  const [showHome, setShowHome] = useState(true);

  function goHome() {
    setShowPlace(0);
  }
  function showMap() {
    setShowHome(false);
  }
  function goPlace1() {
    setShowPlace(1);
  }
  function goPlace2() {
    setShowPlace(2);
  }
  function goPlace3() {
    setShowPlace(3);
  }
  function goPlace4() {
    setShowPlace(4);
  }
  function goPlace5() {
    setShowPlace(5);
  }
  function goPlace6() {
    setShowPlace(6);
  }

  function playSound() {
    sound.play();
  }

  return (
    <div className="bg-black h-full min-h-screen">
      {showHome ? (
        <div className="w-screen">
          <div className="absolute right-0 opacity-0">
            <Button label="Secret" onClick={showMap} />
          </div>
          <div className="container max-w-screen-sm m-auto text-center">
            <h3 className="pt-10 text-center text-white uppercase text-lg">
              TO BE A FLY ON THE WALL
            </h3>
            <h1 className="pt-10 pb-3 text-center text-white text-3xl font-light">
              The online performance has ended.
            </h1>
            <h1 className="pb-10 text-center text-primary text-3xl font-light">
              Thanks for watching!
            </h1>
            <div className="player-wrapper">
              <ReactPlayer
                playing={false}
                className="react-player"
                url="https://vimeo.com/604212428/580f9c0f64"
                width="100%"
                height="100%"
                playsinline
                muted
                controls
              />
            </div>
            <div className="pt-10 pb-10">
              <a
                href="https://instagram.com/to.be.a.fly"
                target="_blank"
                rel="noreferrer"
                className="mx-5 text-white underline"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/greta.schuster.7"
                target="_blank"
                rel="noreferrer"
                className="mx-5 text-white underline"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg bg-map w-screen h-screen cursor-fly">
          {showPlace === 0 && <Header />}
          <div>
            <Badge place="1" onClick={goPlace1} onMouseEnter={playSound} />
            <Badge place="2" onClick={goPlace2} onMouseEnter={playSound} />
            <Badge place="3" onClick={goPlace3} onMouseEnter={playSound} />
            <Badge place="4" onClick={goPlace4} onMouseEnter={playSound} />
            <Badge place="5" onClick={goPlace5} onMouseEnter={playSound} />
            <Badge place="6" onClick={goPlace6} onMouseEnter={playSound} />
          </div>

          {showPlace !== 0 && (
            <div className="fixed top-0 left-0 z-50 fs-fadein-fast">
              <ArrowBack onClick={goHome} />
            </div>
          )}
          {showPlace === 1 && <MultiVideo videos={videos.place1} />}
          {showPlace === 2 && <MultiVideo videos={videos.place2} />}
          {showPlace === 3 && <MultiVideo videos={videos.place3} />}
          {showPlace === 4 && <MultiVideo videos={videos.place4} />}
          {showPlace === 5 && <MultiVideo videos={videos.place5} />}
          {showPlace === 6 && <MultiVideo videos={videos.place6} />}
        </div>
      )}
    </div>
  );
};

export default Home;
