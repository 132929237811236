import React from "react";
import classnames from "classnames";

const ButtonRound = ({ label, active, onClick }) => {
  return (
    <button
      className={classnames([
        "m-1 w-10 h-10 rounded-full text-2xl transition-all transform duration-300 ease-in-out",
        !active && "bg-white text-black hover:bg-grey",
        active && "bg-black text-white",
      ])}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ButtonRound;
